<template>

    <auth-content v-if="world.modules.orgchart">
      <el-header :title="$t('modules.orgchart.title')" slot="header">
      </el-header>

      <tree
        :identifier="(elem) => elem.id"
        v-if="formattedData"
        :data="formattedData"
        node-text="full_name"
        layoutType="vertical"
        :zoomable="false"
        popUpPlacement="bottom"
        :strokeWidth="5"
      >
        <template #popUp="{data}">
          <div class="m-2">
            <div class="card" v-if="data">
              <div class="card-body text-center">
                <img width="90" v-if="data.avatar && data.avatar && data.avatar.thumb" :src="data.avatar.thumb" class="rounded mb-2"/>
                <h4 class="m-0" v-if="data.full_name">
                  {{data.full_name}}
                </h4>
                <div class="mt-2" v-if="data.position && data.position != ''">
                  <small>{{data.position}}</small>
                </div>
              </div>
            </div>
          </div>
        </template>
      </tree>

    </auth-content>

</template>

<script>

import _ from 'lodash'
import {tree} from 'vued3tree'

import {
  WORLD_USERS_REQUEST,
} from '@/store/mutations-types'

import store from '@/store'

export default {
  name: 'Orgchart-Index',
  components: {
    tree
  },
  data () {
    return {
      formattedData: false,
      world_users: false,
      processedUsers: [],
    }
  },
  mounted () {
  },
  methods: {
    usersWithManager (managerId) {
      let users = _.filter(this.world_users, (user) => {
        return user.pivot.manager_id == managerId
      })
      return _.map({...users}, (user) => {
        return {
          id: user.id,
          full_name: user.full_name ? user.full_name : user.email,
          avatar: user.avatar,
          position: user.position,
          children: [],
        }
      })
    },
    addChildrenToManager(child, manager_id, items = null) {
      if (items == null) {
        items = this.formattedData.children
      }
      let managerFound = false
      items.map(user => {
        if (user.id == manager_id) {
          managerFound = true
          user.children.push({
            id: child.id,
            full_name: child.full_name,
            avatar: child.avatar,
            position: child.position,
            children: [],
          })
        }
        if (!managerFound && user.children) {
          managerFound = this.addChildrenToManager(child, manager_id, user.children)
        }
      })
      return managerFound
    },
    formatData () {
      let formattedUsers = {}
      if (this.usersWithoutManagersLength == 1) {
        formattedUsers = {
          id: this.headUser.id,
          full_name: this.headUser.full_name,
          avatar: this.headUser.avatar,
          position: this.headUser.position,
          children: this.usersWithManager(this.headUser.id)
        }
        this.processedUsers.push(this.headUser.id)
        this.usersWithManager(this.headUser.id).map(user => {
          this.processedUsers.push(user.id)
        })
      } else {
        formattedUsers = {
          full_name: '',
          avatar: '',
          position: '',
          children: this.usersWithoutManagers
        }
        this.usersWithoutManagers.map(user => {
          this.processedUsers.push(user.id)
        })
      }
      this.formattedData = formattedUsers
      let debugTest = 0
      while (this.processedUsers.length < this.world_users.length && debugTest < 100) {
        this.world_users.map(user => {
          if (!_.includes(this.processedUsers, user.id)) {
            let tryToAddChildren = this.addChildrenToManager(user, user.pivot.manager_id)
            if (tryToAddChildren) {
              this.processedUsers.push(user.id)
            }
          }
        })
        debugTest++
      }
    }
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld'];
    },
    headUser () {
      if (this.usersWithoutManagersLength != 1) {
        return null
      } else {
        return _.find(this.world_users, (user) => {
          return user.pivot.manager_id == null
        })
      }
    },
    usersWithoutManagers () {
      return this.usersWithManager(null)
    },
    usersWithoutManagersLength () {
      return this.usersWithoutManagers.length
    },
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('world/' + WORLD_USERS_REQUEST, store.getters['auth/getWorld'].id).then(world_users => {
      next(vm => {
        vm.world_users = world_users
        vm.formatData()
      })
    })
  }
}
</script>

<style lang="scss" scoped>

@import '../../../src/assets/scss/_palette.scss';

/deep/ .treeclass {
  width: 100%;
  min-height: 80vh;
}
/deep/ .treeclass .nodetree text {
  font-size: 1em;
  font-weight: bold;
  transform: rotate(-90deg);
}
/deep/ .treeclass .linktree {
    stroke: mix(white, $color-info-700);
    stroke-opacity: 1;
}
</style>
